<template>
  <div class="consumme-info">
    <div class="title">消费信息</div>
    <div class="detail">
      <div class="titles">
        <div class="goods-name">商品</div>
        <div>单价</div>
        <div>数量</div>
        <div v-if="route.query.type==1">理疗师</div>
        <div v-else>销售</div>
        <div>商品优惠</div>
        <div>小计</div>
      </div>
      <div class="goods-info">
        <template
          v-if="
            orderDetail && orderDetail.type == 1
          "
        >
          <template :key="index" v-for="(item, index) in orderDetail.itemList">
            <div class="goods-item">
              <div class="goods-name">{{ item.productName }}</div>
              <div>¥{{ item.price .toFixed(2) }}</div>
              <div>{{ item.count }}</div>

              <div>
                <template v-if="orderDetail && orderDetail.saleName">
                  <div>
                    {{ orderDetail.saleName }}
                    <span v-if="orderDetail.isPoint">(回客)</span>
                  </div>
                </template>
              </div>
              <div>0.00</div>
              <div>¥{{ (item.count * item.price).toFixed(2) }}</div>
            </div>
          </template>
        </template>

        <template v-else>
          <template :key="index" v-for="(item, index) in orderDetail.itemList">
            <div class="goods-item">
              <div class="goods-name">{{ item.productName }}</div>
              <div>¥{{ item.price.toFixed(2) }}</div>
              <div>{{ item.count }}</div>
              <div>
                <template v-if="orderDetail ">
                  <div>
                    {{ orderDetail.saleName }}
                    <span v-if="orderDetail.xukaFlag == 1">(续卡)</span>
                    <span v-if="orderDetail.firstCardOrder == 1">(首购)</span>
                  </div>
                </template>
              </div>

              <div>0.00</div>
              <div>¥{{ (item.count * item.price) .toFixed(2) }}</div>
            </div>
          </template>
        </template>

        <div class="sum">
          <div
            v-if="orderDetail && orderDetail.payStatus > 1"
            class="total-box"
          >
            <div class="total1">付款:</div>
            <div class="total2">
              <template v-for="payTypeItem in orderDetail.payWayList">
                <div style="display: flex;justify-content: space-between;">
                  <div class="typeStr">
                    {{ payTypeItem.payWayName }}
                    <!-- <span v-if="payTypeItem.phone">({{ payTypeItem.phone }})</span> -->
                  </div>
                  <div>¥{{ payTypeItem.price.toFixed(2) }}</div>
                </div>
              </template>
            </div>
          </div>

          <template v-if="orderDetail">
            <template v-if="orderDetail.payStatus == 1">
              <div class="total">
                <div class="key">合计:</div>
                <div v-if="orderDetail" class="value">
                  {{ orderDetail.totalPrice.toFixed(2) }}
                </div>
              </div>
              <div class="total">
                <div class="key">待支付:</div>
                <div v-if="orderDetail" class="value">
                  {{ orderDetail.totalPrice.toFixed(2) }}
                </div>
              </div>
            </template>
            <template v-else>

              <div class="total">
                <p style="width: 60px;">优惠:</p>
                <div style="display: flex;width: 200px;justify-content: space-between;" v-if="orderDetail.discountList&&orderDetail.discountList.length">
                  <p class="pay-type-str" v-for="discount in orderDetail.discountList">
                    {{ discount }}
                  </p>
                  <span class="total-price"> ¥ {{ orderDetail.discountPrice }}</span>
                </div>
                <div v-else style="display: flex;width: 200px;justify-content: flex-end;">
                  <span class="total-price"> ¥ {{ orderDetail.discountPrice }}</span>
                </div>
                <div>
                </div>
              </div>
              <div class="total">
                <div class="key">合计收款:</div>
                <div style="display: flex;width: 204px;justify-content: flex-end;">
                  <span class="total-price"> ¥ {{ orderDetail.payPrice }}</span>
                </div>
              </div>
            </template>
          </template>
        </div>

        <div class="btns">
          <template v-if="orderDetail && orderDetail.payStatus != 4">
            <div class="btn-wrap">
              <el-button :disabled="!orderDetail.canRevoke" plain @click="handleCancleOrder">撤单</el-button>
              <el-button disabled plain>打印小票</el-button>
              <div v-if="orderDetail && orderDetail.payStatus == 2" style="margin: 0 10px;">
              <el-button
                  v-if="orderDetail.type == 1"
                  :disabled="!(serveInfo&&serveInfo.payWayList&&serveInfo.payWayList.length)"
                  @click="handleCancleClick"
                  plain
                  v-has="'order:refund'"
                  >主动退款</el-button
                >
              <el-button
                v-else
                :disabled="!(serveInfo.kindId==5||serveInfo.kindId==6||serveInfo.kindId==3)"
                @click="handleCancleClick"
                plain
                v-has="'order:refund'"
                >主动退款</el-button
              >
              </div>

              <template
                v-if="orderDetail && orderDetail.payStatus == 1"
              >
                <el-button @click="handlePayClick"> 确认收款</el-button>
              </template>

              <template
                v-if="orderDetail && orderDetail.payStatus == 2"
              >
                <el-button disabled> 已支付</el-button>
              </template>

              <template
                v-if="orderDetail && orderDetail.payStatus == 3"
              >
                <el-button disabled plain> 已取消</el-button>
              </template>

              <el-button @click="handleUpdateScoreClick" type="primary" v-has="'order:edit:performance'"
                >修改业绩</el-button
              >
            </div>
          </template>

          <template v-else>
            <el-button disabled plain> 已取消</el-button>
          </template>
        </div>
      </div>
    </div>
  </div>

  <refundDialog
    v-if="dialogVisible"
    :refundInfo="serveInfo"
    :orderDetail="orderDetail"
    :orderNo="route.query.orderNo"
    :type="currentType"
    @handleSureRefundClick="handleSureRefundClick"
    :dialogVisible="dialogVisible"
    @changeDialogVisible="changeDialogVisible"
  ></refundDialog>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch,computed } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { popup } from '@/utils/pop-up'
import { COMPANY_ID } from '@/common/constant'
import { setOrderNo, setRefund, cancelOrder, serveLastRefund,cardRefund, getTuikuanInfo,revokeOrder } from '@/service/main/order'
import refundDialog from '../../base-ui/refund-dialog/refund-dialog.vue'
import { useRoute } from 'vue-router'
import cache from '@/utils/cache'
import { getPayTypeStrFunc } from '../../config'
import { getOrderPayType } from '@/service/main/order'

export default defineComponent({
  props: {
    orderDetail: {
      type: Object,
      defalult() {
        return {}
      }
    },
    serveInfo: {
      type: Object,
      defalult() {
        return {}
      }
    }
  },
  components: {
    refundDialog
  },
  emits: ['refreshPage'],
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const baseInfo = reactive({
      userName: "",
      userId: "0",
      serveName: "",

    })

    const handlePayClick = () => {
      let orderType = props.orderDetail.orderKind
      if (orderType == 3 || orderType == 4 || orderType == 5 || orderType == 7) {
        router.replace({
          path: '/main/order/openCard',
          query: {
            userId: props.orderDetail.userId,
            phone: props.orderDetail.userMobile,
            orderNo: props.orderDetail.subOrderNo,
			shopId: props.orderDetail.shopId,
          }
        })
      }

      if (props.orderDetail && props.orderDetail.orderKind == 1) {
        router.replace({
          path: '/main/order/billing',
          query: {
            userId: props.orderDetail.userId,
            phone: props.orderDetail.userMobile,
            orderNo: props.orderDetail.subOrderNo
          }
        })
      }
    }

    const cancleOrder = async (item) => {
      const res = await cancelOrder({
        orderKind: item.orderKind == 1 || item.orderKind == 2 ? 1 : 2,
        orderNo: item.subOrderNo,
        userId: item.userId,
        appNo: item.orderKind == 1 || item.orderKind == 2 ? item.relateAppNo : '',
        companyId: COMPANY_ID,
        paystatus: item.payStatus == 1 ? 0 : 1
      })

      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        emit('refreshPage')
      } else {
        ElMessage.error('操作失败!')
      }
      dialogVisible.value = false
    }

    const handleCancleClick = async () => {
      dialogVisible.value = true
    }
    const handleCancleOrder = () =>{
      popup('确认撤单吗?', async () => {
        const res = await revokeOrder(route.query.id)
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          router.replace({path: '/main/order/list'})
        }else{
          ElMessage({
            message: res.msg,
            type: 'error',
          })
        }
      })
    }

    const handleSureRefundClick = async (event) => {
      popup('确认退款吗?', async () => {
        if (currentType.value==1) {
          const res = await serveLastRefund({...event,id:route.query.id})
          if (res.code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'success',
            })
            emit('refreshPage')
          }else{
            ElMessage({
              message: res.msg,
              type: 'error',
            })
          }
        }else{
          const res = await cardRefund({...event,id:route.query.id})
          if (res.code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'success',
            })
            emit('refreshPage')
          }else{
            ElMessage({
              message: res.msg,
              type: 'error',
            })
          }
        }
        dialogVisible.value = false
      })
    }

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    const currentType = computed(() => route.query.type)
    watch(() => props.orderDetail, (n) => {
      if (n != null) {
        // console.log(n);
        let { userId, userRealname } = n
        baseInfo.userId = userId
        baseInfo.userName = userRealname
        // baseInfo.serveName = n.buyItems[0].itemName
      }
    })

    const refundInfo = reactive({
      refundForm: {},
      payTypeArr: [],
      sum: 0
    })

    const initPayType = async () => {
      const res = await getOrderPayType(route.query.orderNo)
      console.log(res);
      let list = res.data.list
      let sum = 0, payTypeArr = [], refundForm = {}
      // console.log(list);

      list.forEach((item, index) => {
        sum += item.payprice
        // 写死了
        // 写死了
        // 写死了
        let payway = item.payway
        if (payway == 'ONLINEPAY') {
          payway = 'CashWechat'
        }
        let payTypeStr = getPayTypeStrFunc(payway)
        let obj = {
          typeStr: payTypeStr,
          payprice: item.payprice / 100,
          phone: item.phone ? item.phone : ''
        }
        payTypeArr.push(obj)

        // 退款方式 现金和原路返回都支持
        let flag = false
        if (payTypeStr == '微信' || payTypeStr == '支付宝' || payTypeStr == '线上支付') {
          flag = true
        }
        // console.log(flag);

        refundForm[index] = {}
        refundForm[index]['price'] = item.payprice / 100  // 退款金额
        refundForm[index]['maxprice'] = item.payprice / 100 //最大退款金额
        refundForm[index]['type'] = '2' //退款方式 默认原路返回
        refundForm[index]['isRefundCash'] = flag //是否能退现金
        refundForm[index]['cardNo'] = item.cardNo //卡号
        refundForm[index]['payway'] = payway //支付方式
      })

      refundInfo.sum = (sum / 100).toFixed(2)
      refundInfo.payTypeArr = payTypeArr
      refundInfo.refundForm = refundForm
    }

    const handleUpdateScoreClick = () => {
      let { shopId } = props.orderDetail

      router.push({
        path: "/main/order/updateStaffScore",
        query: {
          orderId: route.query.id,
          type: route.query.type
        }
      })
      // console.log(props);
      // console.log(2)
    }


    const initPage = async () => {
      // initPayType()

    }
    initPage()


    return {
      handleUpdateScoreClick,
      currentType,
      handleCancleClick,
      handlePayClick,
      COMPANY_ID,
      refundDialog,
      dialogVisible,
      changeDialogVisible,
      handleSureRefundClick,
      route,
      baseInfo,
      refundInfo,
      handleCancleOrder
    }

  }
})
</script>

<style scoped lang="less">
.consumme-info {
  .pay-type-str {
    display: flex;

    & > div {
      flex: 1;
    }
  }

  // .card {
  // }

  .detail {
    padding-left: 36px;
    padding-bottom: 30px;

    .btns {
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid rgb(225, 227, 228);

      .btn-wrap {
        // width: calc((100% / 8)*2);
        display: flex;
        justify-content: space-evenly;
      }
    }

    .sum {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 20px 0;

      .total-box {
        width: calc((100% / 8) * 2);
        text-align: left;
        display: flex;
        font-size: 14px;
        margin: 10px 0;

        .total1 {
          width: 60px;
          // text-align: right;
        }

        .total2 {
          // flex: 1;
          width: 200px;
          .pay-type-str {
            .typeStr {
              // padding-left: 4px;
            }
          }
        }

        .total-price {
          color: #ff6600;
          font-size: 16.5px;
          font-weight: 600;
        }

        span {
          display: inline-block;
          margin-right: 3px;
        }
      }

      .total {
        width: calc((100% / 8) * 2);
        text-align: left;
        display: flex;
        font-size: 14px;
        margin: 10px 0;

        .total-price {
          color: #ff6600;
          font-size: 16.5px;
          font-weight: 600;
          padding-left: 28px;
        }

        span {
          display: inline-block;
          margin-right: 3px;
        }

        // & > div {
        //   flex: 1;
        // }
      }
    }

    .goods-info {
      border: 1px solid rgb(225, 227, 228);
      border-top: none;

      .goods-item:hover {
        background-color: rgb(246, 244, 255);
      }

      .goods-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        min-height: 70px;
        padding: 0 20px;
        font-size: 14px;
        box-shadow: inset 0 -1px 0 0 #ebedf0;

        & > div {
          flex: 1;
        }

        .goods-name {
          flex: 2;
        }
      }
    }

    .titles {
      margin-top: 30px;
      padding: 0 20px;
      display: flex;
      line-height: 56px;
      background-color: rgb(247, 248, 250);
      font-weight: 500;
      border: 1px solid rgb(225, 227, 228);
      border-bottom: none;

      .goods-name {
        flex: 2;
      }

      & > div {
        flex: 1;
      }
    }
  }

  .title {
    position: relative;
    line-height: 48px;
    background-color: rgb(248, 248, 248);
    padding-left: 26px;
  }

  .title::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 20px;
    background-color: #2a82e4;
    left: 12px;
    top: 14px;
  }
}
</style>
