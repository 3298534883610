<template>
  <div
    v-if="orderDetail && orderDetail.type == 1"
    class="steps">
    <!-- <h1>{{orderDetail}}</h1> -->
    <div class="title">订单详情</div>
    <div class="step">
      <!-- 1小程序(线上) -->
      <!-- <template v-if="orderDetail && orderDetail.orderSource==1"> -->
        <el-steps finish-status="success" :active="ruleForm.active" align-center>
          <el-step title="下单时间" :description="ruleForm.appCreateTime" />
          <el-step title="预约时间" :description="ruleForm.appTime" />
          <el-step title="到店时间" :description="ruleForm.arrivalTime" />
          <el-step title="收款时间" :description="ruleForm.payTime" />
          <el-step title="评价时间" :description="ruleForm.evaluationTime" />
        </el-steps>
      <!-- </template> -->
      <!-- 1小程序(线下) -->
      <!-- <template v-else-if="orderDetail && orderDetail.orderSource==2">
        <el-steps finish-status="success" :active="ruleForm.active" align-center>
          <el-step title="下单时间" :description="ruleForm.appCreateTime" />
          <el-step title="预约时间" :description="ruleForm.appTime" />
          <el-step title="到店时间" :description="ruleForm.arrivalTime" />
          <el-step title="收款时间" :description="ruleForm.payTime" />
          <el-step title="评价时间" :description="ruleForm.evaluationTime" />
        </el-steps>
      </template> -->
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'

export default defineComponent({
  props: {
    orderDetail: {
      type: Object,
      defalult() {
        return {}
      }
    }
  },
  components: {

  },
  setup(props, { emit }) {

    const ruleForm = reactive({
      appCreateTime: '',
      arrivalTime: '',
      payTime: "",
      evaluationTime: '',
      active: '',
      orderFinishTime:''

    })

    watch(() => props.orderDetail, (n) => {
      let active = 1
      let detail = n
      let serveItem=n.serveItems
      ruleForm.appCreateTime = detail.appCreateTime
      ruleForm.orderFinishTime = detail.orderFinishTime
      ruleForm.arrivalTime = detail.arrivalTime == null ? '-' : detail.arrivalTime
      ruleForm.payTime = detail.payTime == null ? '-' : detail.payTime
      ruleForm.evaluationTime = detail.evaluationTime == null ? '-' : detail.evaluationTime
      ruleForm.appTime = detail.appTime == null ? '-' : detail.appTime
      if (detail.evaluationTime != null) {
        active = 5
      } else if (detail.payTime != null) {
        active = 4
      } else if (detail.arrivalTime != null) {
        active = 3
      } else if (ruleForm.appTime != null) {
        active = 2
      }
      ruleForm.active = active


    }, {
      deep: true
    })

    return {
      ruleForm
    }

  }
})
</script>

<style scoped lang="less">
.steps {

  .step {
    margin: 40px 0;
  }

  .title {
    position: relative;
    line-height: 48px;
    background-color: rgb(248, 248, 248);
    padding-left: 26px;
  }
}
</style>




