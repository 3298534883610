<template>
  <div class="refund-info">
    <div class="title">退款信息</div>
    <div class="detail">
      <div class="titles">
        <div>退款方式</div>
        <div>退款操作人</div>
        <div>退款时间</div>
        <div>金额</div>
      </div>
      <div class="info-list">
        <template v-for="item in extraRefundList">
          <div class="item">
            <div>{{  item.payWayName }}</div>
            <div>{{  item.refundUser }}</div>
            <div>{{  item.refundTime }}</div>
            <div>¥{{  item.price.toFixed(2)  }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {
    extraRefundList: {
      type: Array,
      defalult() {
        return []
      }
    }
  },
  components: {

  },
  setup(props) {
    console.log(props); 
    const route = useRoute()

    const initPage = () => {

    }
    initPage()

    return {

    }

  }
})
</script>

<style scoped lang="less">
.refund-info {
  .detail {
    padding-left: 36px;

    .info-list {
      flex-direction: column;
      border: 1px solid #e1e3e4;
      border-top: none;

      .item {
        display: flex;
        padding: 0 10px;
        line-height: 70px;
        box-shadow: inset 0 -1px 0 0 #ebedf0;
        &>div {
          flex: 1;
        }
      }
    }

    .titles {
      background-color: #f7f8fa;
      font-weight: 500;
      border: 1px solid #e1e3e4;
      border-bottom: none;
      line-height: 56px;
      padding: 0 10px;
      margin-top: 30px;
    }

    .titles,
    .info-list {
      display: flex;
      &>div {
        flex: 1;
      }
    }
  }

  .title {
    position: relative;
    line-height: 48px;
    background-color: rgb(248, 248, 248);
    padding-left: 26px;
  }

  .title::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 20px;
    background-color: #2a82e4;
    left: 12px;
    top: 14px;
  }
}
</style>




