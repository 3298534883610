export const breadcrumbList = [
  {
    name: '订单管理',
    path: ''
  },
  {
    name: '订单列表',
    path: '/main/order/list'
  },
  {
    name: '订单详情',
    path: ''
  }
]

export const refundRules = {
  comment: [
    { required: true, message: '请输入退款备注!', trigger: 'blur' },
    { min: 6, message: '最少5个字!', trigger: 'blur' }
  ]
}

export const refundType = [
  {
    label: '原路返回',
    value: '2'
  },
  {
    label: '现金',
    value: '1'
  },
  {
    label: 'OA报销',
    value: '3'
  }
]


export const getPayTypeStrFunc = (key) => {
  let str = ''
  switch (key) {
    case 'Cash':
      str = '现金'
      break
    case 'CashWechat':
      str = '微信'
      break
    case 'danci':
      str = '线上支付'
      break
    case 'CashAlipay':
      str = '支付宝'
      break
    case 'youxianbenjin':
      str = '有限次卡绑定服务'
      break
    case 'yuebenjin':
      str = '余额本金'
      break
    case 'yuezengsong':
      str = '余额赠送'
      break
    case 'youxianzengsong':
      str = '有限次卡赠送服务'
      break
    case 'buxianbenjin':
      str = '不限次卡绑定服务'
      break
    case 'buxianzengsong':
      str = '不限次卡赠送服务'
      break
    case 'dingzhiben':
      str = '定制卡绑定服务'
      break
    case 'dingzhizengsong':
      str = '定制卡赠送服务'
      break
    case 'shangpin':
      str = '商品兑换服务'
      break
    case 'duihuan':
      str = '兑换券'
      break
    case 'chuzhikabenjin':
      str = '储值卡本金'
      break
    case 'chuzhikazengsong':
      str = '储值卡赠送'
      break
    case 'zhekou-benjin':
      str = '折扣卡本金'
      break
    case 'tuangou':
      str = '团购（新美大）'
      break
    case 'yuding':
      str = '预定（新美大）'
      break
    case 'douyin':
      str = '抖音'
      break
    case 'zengsong':
      str = '赠送'
      break
    case 'miandan':
      str = '免单'
      break
    case 'B-endCooperation':
      str = 'B端合作'
      break
    case 'POS-machine':
      str = 'POS机'
      break
    case 'duihuan':
        str = '兑换券'
    break

  }
  return str
}
