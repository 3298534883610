<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" title="添加备注" width="30%">
      <el-input show-word-limit maxlength="200" v-model="ruleForm.textarea" :rows="4" type="textarea"
        placeholder="输入订单备注" />
      <template #footer>
        <el-button :disabled="ruleForm.textarea.length == 0" @click="handleSaveClick" type="primary">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import { useRoute } from 'vue-router'
import {editRemark} from '@/service/main/order'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    remark: {
      type: String,
      default: ''
    },
    orderId: {
      type: Number,
      default: -1
    }

  },
  components: {

  },
  emits: ['changeDialogVisible','refreshPage'],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const isShow = ref(props.dialogVisible)
    const remark = computed(() => props.remark)
    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
    }, {
      deep: true
    })
    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })
    watch(remark, (newValue) => {
      ruleForm.textarea=newValue
    }, {
      deep: true
    })

    const ruleForm = reactive({
      textarea: ""
    })


    onUnmounted(() => {
      isShow.value = false

    })

    const handleSaveClick = () => {
      editRemark({id:props.orderId,remark:ruleForm.textarea}).then(res=>{
        if (res.code==0) {
          isShow.value = false
          ruleForm.textarea = ''
          emit('refreshPage')
        }
      })
    }

    return {
      handleSaveClick,
      isShow,
      COMPANY_ID,
      ruleForm,
      remark
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  z-index: 999;

  .teacher {
    display: flex;
    flex-wrap: wrap;

    .active {
      background-color: rgb(255, 162, 68);
      color: white;
    }

    .item {
      border-radius: 6px;
      cursor: pointer;
      padding: 0 10px;
      width: 140px;
      line-height: 45px;
      margin: 0 10px;
      border: 1px solid rgba(217, 217, 217, .8);
    }

  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }



}
</style>
