<template>
  <div class="refund-dialog">
    <pageDialog
      width="900px"
      title="主动退款"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
    >
      <el-form
        label-position="left"
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="refundRules"
        label-width="110px"
        class="demo-ruleForm"
        status-icon
      >
        <el-scrollbar height="540px">
          <el-form-item v-if="type == 1" label="退款金额:">
            <div class="refund-money">
              <div class="titles">
                <div>支付方式</div>
                <div>支付金额</div>
                <div>退款金额</div>
                <div>退款方式</div>
              </div>

              <template v-for="(item, index) in ruleForm.payWayList" :key="index">
                <div class="refund-form">
                  <div>
                    {{ item.payWayName }}
                  </div>
                  <div>¥{{ Number(item.maxprice).toFixed(2) }}</div>
                  <div>
                    <el-form-item :prop="'payWayList.'+index+'.price'"
                    :rules="[{ required: true,message :'金额不能为空',trigger:       
                       'blur'}]"
                    >
                      <el-input
                        @input="handleRefundInput($event, index)"
                        @change="changePrice"
                        size="small"
                        v-model="ruleForm.payWayList[index].price"
                        placeholder=""
                      >
                        <template #suffix> 元 </template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item :prop="'payWayList.'+index+'.payWayType'"
                    :rules="[{ required: true,message :'退款方式不能为空',trigger:       
                       'blur'}]"
                    >
                      <el-select
                        size="small"
                        v-model="ruleForm.payWayList[index].payWayType"
                        class="m-2"
                        placeholder=""
                      >
                        <el-option
                          v-for="item in refundType"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </template>
            </div>
          </el-form-item>

          <el-form-item v-if="type == 2&&refundInfo.kindId==5||refundInfo.kindId==6" label="开卡情况:" prop="name">
            <div class="chuzhi-card">
              <div class="title">
                <div class="left">
                  <div class="img">
                    <template v-if="refundInfo">
                      <template v-if="refundInfo.kindId == 3">
                        <img src="@/assets/img/main/card/card1.png" alt />
                      </template>
                      <template v-if="refundInfo.kindId == 4">
                        <img src="@/assets/img/main/card/card2.png" alt />
                      </template>
                      <template v-if="refundInfo.kindId == 5||refundInfo.kindId == 6">
                        <img src="@/assets/img/main/card/card3.png" alt />
                      </template>
                      <template v-if="refundInfo.kindId == 7">
                        <img src="@/assets/img/main/card/card4.png" alt />
                      </template>
                      <template v-if="refundInfo.kindId == 8">
                        <img src="@/assets/img/main/card/card1.png" alt />
                      </template>
                    </template>
                  </div>
                  <div v-if="refundInfo && refundInfo" class="info">
                    <div>{{ refundInfo.cardName }}</div>
                    <div>{{ refundInfo.expireTime }}前有效</div>
                  </div>
                </div>

                <div class="right">
                  <div class="title">
                    <div>本次充值</div>
                    <div>当前余额</div>
                  </div>
                  <div v-if="refundInfo && refundInfo" class="casg-value">
                    <div class="item">
                      <div>
                        本金:{{ refundInfo.sourceCash.toFixed(2) }}
                      </div>
                      <div v-if="type == 2">
                        赠金:{{ refundInfo.sourceCashProm.toFixed(2) }}
                      </div>
                    </div>
                    <div class="item">
                      <div>本金:{{ refundInfo.cash.toFixed(2) }}</div>
                      <div v-if="type == 2">
                        赠金:{{ refundInfo.cashProm.toFixed(2) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item v-if="type == 2&&refundInfo.kindId==3" label="开卡情况:" prop="name">
            <div v-if="refundInfo" class="card">
              <div class="title">
                <div class="left">
                  <template v-if="refundInfo">
                    <template v-if="refundInfo.kindId == 3">
                      <img src="@/assets/img/main/card/card1.png" alt />
                    </template>
                    <template v-if="refundInfo.kindId == 4">
                      <img src="@/assets/img/main/card/card2.png" alt />
                    </template>
                    <template v-if="refundInfo.kindId == 5">
                      <img src="@/assets/img/main/card/card3.png" alt />
                    </template>
                    <template v-if="refundInfo.kindId == 7">
                      <img src="@/assets/img/main/card/card4.png" alt />
                    </template>
                  </template>

                  {{ refundInfo.cardName }}
                </div>
                <div class="right">{{ refundInfo.expireTime }}前有效</div>
              </div>
              <div class="card-form">
                <div class="cash">
                  <div>
                    购卡金额:{{ refundInfo.sourceCash.toFixed(2) }}
                  </div>
                </div>
                <div>总次数:{{ cikaInfo.totalQty }}次</div>
                <div>已使用次数:{{ cikaInfo.useTotalQty }}次</div>
              </div>
            </div>
          </el-form-item>

          <el-form-item v-if="type == 2&&refundInfo.kindId==5||refundInfo.kindId==6" label="扣除余额:" prop="name">
            <div v-if="refundInfo" class="deduct-price">
              <div>
                <span class="sp1">本金扣除</span>
                <el-input
                  readonly
                  size="small"
                  v-model="refundInfo.cash"
                  placeholder=""
                >
                  <template #suffix> 元 </template>
                </el-input>
                <span class="sp2">本次退卡后，本金余额:¥0.00</span>
              </div>

              <div>
                <span class="sp1">赠金扣除</span>
                <el-input
                  readonly
                  size="small"
                  v-model="refundInfo.cashProm"
                  placeholder=""
                >
                  <template #suffix> 元 </template>
                </el-input>
                <span class="sp2">本次退卡后，赠金余额:¥0.00</span>
              </div>
            </div>
          </el-form-item>


          <el-form-item v-if="type == 2&&refundInfo.kindId==3" label="购卡项目:" prop="name">
            <div class="project">
              <div class="titles">
                <div>商品名称</div>
                <!-- <div>市场价(元)</div> -->
                <div>购买次数</div>
                <div>消耗次数</div>
                <div>原单价</div>
                <div>原消耗金额</div>
              </div>
              <template v-if="refundInfo && refundInfo.cardServe">
                <template v-for="(item, indez) in refundInfo.cardServe">
                  <div class="form">
                    <div>
                      {{ item.name }}
                    </div>
                    <!-- <div>¥{{ (item.avaragePrice).toFixed(2) }}</div> -->
                    <div>
                      {{ item.count }}
                    </div>
                    <div>
                      {{ item.consume }}
                    </div>
                    <div>¥{{ item.price.toFixed(2) }}</div>
                    <div>
						¥{{ item.consumptionAmount }}
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </el-form-item>
          <el-form-item v-if="type == 2&&refundInfo.kindId==3" label="赠送项目:" prop="name">
            <div class="project">
              <div class="titles">
                <div>商品名称</div>
                <div>购买次数</div>
                <div>消耗次数</div>
                <div>原单价</div>
                <div>原消耗金额</div>
              </div>
              <template v-if="refundInfo && refundInfo.cardGift">
                <template v-for="item in refundInfo.cardGift">
                  <div class="form">
                    <div>
                      {{ item.name }}
                    </div>
                    <div>
                      {{ item.count }}
                    </div>
                    <div>
						{{ item.consume }}
                    </div>
                    <div>¥{{ item.price.toFixed(2) }}</div>
                    <div>
						¥{{ item.consumptionAmount }}
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </el-form-item>
          <el-form-item v-if="type == 2" label="退款金额:" prop="name">
            <div class="refund-money">
              <div class="titles">
                <div>支付方式</div>
                <div>退款金额</div>
                <div>退款方式</div>
              </div>
              <template v-for="(item, index) in ruleForm.payWayList" :key="index">
                <div class="refund-form">
                  <div>
                    {{ item.payWayName }}
                  </div>
                  <div>
                    <el-form-item :prop="'payWayList.'+index+'.price'"
                    :rules="[{ required: true,message :'金额不能为空',trigger:       
                       'blur'}]"
                    >
                      <el-input
                        @input="handleRefundInput($event, index)"
                        @change="changePrice"
                        size="small"
                        v-model="ruleForm.payWayList[index].price"
                        placeholder=""
                      >
                        <template #suffix> 元 </template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item :prop="'payWayList.'+index+'.payWayType'"
                    :rules="[{ required: true,message :'退款方式不能为空',trigger:       
                       'blur'}]"
                    >
                      <el-select
                        size="small"
                        v-model="ruleForm.payWayList[index].payWayType"
                        class="m-2"
                        placeholder=""
                      >
                        <el-option
                          v-for="item in refundType"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </template>
            </div>
          </el-form-item>
          <el-form-item
            v-if="type!=1"
            label="退款门店和理疗师"
          >
            <div class="shop-teacher">
              <el-select
                @change="handleShopChnage"
                clearable
                v-model="ruleForm.shopId"
                class="m-2"
                placeholder="选择门店"
              >
                <el-option
                  v-for="item in shopList"
                  :key="item.shopId"
                  :label="item.shopName"
                  :value="item.shopId"
                >
                </el-option>
              </el-select>

              <el-select
                class="teacher-select"
                @clear="handleClearClick"
                clearable
                v-model="ruleForm.empId"
                filterable
                placeholder="选择理疗师"
                suffix-icon="search"
              >
                <el-option
                  v-for="item in shopTeacherList"
                  :key="item.userId"
                  :label="item.name"
                  :value="item.userId"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="退款说明:" prop="remark" :rules="[{ required: true,message :'退款说明不能为空',trigger:       
                       'blur'}]">
            <el-input
              v-model="ruleForm.remark"
              :rows="4"
              type="textarea"
              placeholder=""
            />
          </el-form-item>
        </el-scrollbar>

        <el-form-item label="合计退款金额:" prop="name">
          <div class="total-price">
            <div class="price">

              <template v-if="type == 1">
                ¥{{ Number(ruleForm.payPrice).toFixed(2) }}
              </template>
              <template v-if="type == 2">
                ¥{{ Number(ruleForm.payPrice).toFixed(2) }}
              </template>
            </div>
            <div>
              <el-button @click="handleSureRefundClick(ruleFormRef)" type="primary"
                >确认退款</el-button
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
    </pageDialog>
  </div>
</template>

<script lang="js">
/*
退款三个维度
  0 服务
  1 卡
  2 产品
  3折扣卡
*/
import { defineComponent, reactive, ref } from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { refundRules, refundType } from '../../config'
import { getOrderRefoundDetail, getCardInfo } from '@/service/main/order'
import { getOrderReturnShopList } from '@/service/main/reserve'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import { getReturnEmpList } from '@/service/main/order'
import { searchStaff } from '@/service/main/base'
import cache from '@/utils/cache'
import router from '@/router'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    orderNo: {
      type: Number,
      required: true
    },
    /*
    type
    1 服务
    2 购卡

    */
    type: {
      type: Number,
      default: 0
    },
    orderDetail: {
      type: Object,
      default() {
        return {}
      }
    },
    refundInfo: {
      type: Object,
      required: true
    }
  },
  components: {
    pageDialog,

  },
  emits: ['changeDialogVisible', 'handleSureRefundClick'],
  setup(props, { emit }) {
    
    const route = useRoute()

    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)

      if (flag) {
        initPage()
        // console.log(props.orderDetail);
      }
    }

    const handleSureRefundClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const {refundInfo,type} = props
          // 服务
            let flag = true
            let payWayList = ruleForm.payWayList
            // 判断表单是否填写数据了
            for (let key in payWayList) {
              for (let kez in payWayList[key]) {
                if (String(payWayList[key][kez]).length == 0) {
                  flag = false
                  break
                }
              }
            }

            if (flag == false) {
              ElMessage({
                message: '请填写退款金额!',
                type: 'warning',
              })
              return
            }
          

          // 发射退款事件
          // 注意此处值考虑了一个付款方式的情况 没考虑组合支付
          if (type == 1) {
            let list = Object.values(ruleForm.payWayList)
            let sum = 0
            list.forEach(ele => {
              sum += Number(ele.price)
            })
            if (sum < ruleForm.payPrice) {
              ruleForm.payWayList['0'].price = sum
              ruleForm.payWayList['0'].maxprice = sum
            }
            let payWayList=ruleForm.payWayList.map(item=>{
            return{
                price:item.price,
                payWayId:item.payWayId,
                payWay:item.payWayType==1?'Cash':item.payWayType==3?'OA_REIMBURSEMENT':''
              }
            })
            emit('handleSureRefundClick', {
              payWayList,
              remark: ruleForm.remark,
            })
          }
          if (type == 2) {
            let list = Object.values(ruleForm.payWayList)
            let sum = 0
            list.forEach(ele => {
              sum += Number(ele.price)
            })
            if (sum < ruleForm.payPrice) {
              ruleForm.payWayList['0'].price = sum
              ruleForm.payWayList['0'].maxprice = sum
            }
            let payWayList=ruleForm.payWayList.map(item=>{
            return{
                price:item.price,
                id:item.id,
                payWay:item.payWayType==1?'Cash':item.payWayType==3?'OA_REIMBURSEMENT':''
              }
            })
            emit('handleSureRefundClick', {
              cardNo:refundInfo.cardNo,
              shopId:ruleForm.shopId,
              empId:ruleForm.empId,
              payWayList,
              remark: ruleForm.remark,
            })
          }
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const handleBindServeRefundChange = (v1, v2, index) => {
      let n1 = Number(v1)
      let n2 = Number(v2)

      if (n1 > n2) {
        ElMessage({
          message: '退款金额不得超过平均价格!',
          type: 'warning',
        })
        bindRefunList.value[index]['value'] = n2
      }

    }

    const ruleForm = reactive({
      payWayList:[],
      id:route.query.id,
      remark:''
    })

    const detail = ref('')
    const totalPayPrice = ref(0)
    const ruleFormRef = ref(false)
    const cardDetail = ref()
	const cikaRefundAmount = ref(0)

    const cikaInfo = reactive({
      totalQty: 0,
      useTotalQty: 0
    })
    const changePrice = () => {
      let totalPrice = 0
      ruleForm.payWayList.map(item=>{
        totalPrice+=Number(item.price)
      })
      ruleForm.payPrice=totalPrice;
    }

    const handleRefundInput = (price, index) => {
        let maxprice = ruleForm.payWayList[index].maxprice
        if (maxprice < price) {
          ElMessage({
            message: `退款金额不得超过${maxprice}元!`,
            type: 'warning',
          })
          ruleForm.payWayList[index].price = maxprice
        }
        
        if (price < 0) {
          ElMessage({
            message: `输入的金额不合法!`,
            type: 'warning',
          })
          ruleForm.payWayList[index].price = maxprice
        }
        let reg =/^([-+]?[0-9]+[\d]*(.[0-9]{1,})?)$/
        if (!price) return
        let res = reg.test(price);
        if (!res) {
          ElMessage({
            message: `输入的格式不合法!`,
            type: 'warning',
          })
          ruleForm.payWayList[index].price = maxprice
        }
    }


    const handleClearClick = () => {
      let { orderDetail } = props
      if (orderDetail.empId != null&&orderDetail.empId != 0) {
        ruleForm.empId = orderDetail.empId
      } else {
        ruleForm.empId = ''
      }
    }

    const shopList = ref([])
    const getShopListFunu = async () => {
      const res = await getOrderReturnShopList(route.query.id)
      shopList.value = res.data

    }

    const shopTeacherList = ref([])
    const shopOldTeacherList = ref([])
    const getShopTeacherListFunc = async (shopId) => {
      const res = await getReturnEmpList(shopId,route.query.id)
      shopTeacherList.value = res.data
      shopOldTeacherList.value = res.data

    }

    const handleShopChnage = value => {
      if (value) {
        getShopTeacherListFunc(value)
      }else{
        ruleForm.empId = null
        shopTeacherList.value = []
      }
    }


    const bindRefunList = ref([])
    const initPage = async () => {
      let { type, orderDetail } = props
      if (type==1) {
        let { payWayList,price } = props.refundInfo
        ruleForm.payWayList=JSON.parse(JSON.stringify(payWayList))
        ruleForm.payPrice = price
        ruleForm.payWayList.map(item=>{
          item.payWayType=''
          item.maxprice=item.price
        })
      }else{
        let { kindId,price,cardServe,cardGift,payWayList } = props.refundInfo
        if (kindId==5||kindId==6) {
          ruleForm.payWayList=JSON.parse(JSON.stringify(payWayList))
          // ruleForm.price = price
          // ruleForm.refundMaxAmount = price
          ruleForm.payPrice = price
          // ruleForm.payWayType='2'
          ruleForm.payWayList.map(item=>{
            item.payWayType=''
            item.price=item.surplusPrice
            item.maxprice=item.sourcePrice
          })
        }else if (kindId==3){
          ruleForm.payWayList=JSON.parse(JSON.stringify(payWayList))
          ruleForm.payPrice = price
          ruleForm.payWayList.map(item=>{
            item.payWayType=''
            item.price=item.surplusPrice
            item.maxprice=item.sourcePrice
          })
          // ruleForm.refundMaxAmount = price
          // ruleForm.price = price
          // ruleForm.payWayType='2'
          let totalQty=0
          let useTotalQty=0
          let array=[...cardServe,...cardGift]
          array.map(item=>{
            totalQty+=Number(item.count)
            useTotalQty+=Number(item.consume)
          })
          cikaInfo.totalQty = totalQty
          cikaInfo.useTotalQty = useTotalQty
        }
        ruleForm.shopId = orderDetail.shopId
        ruleForm.empId = orderDetail.empId==0?'':orderDetail.empId
        getShopListFunu()
        getShopTeacherListFunc(orderDetail.shopId)
      }
    }
    initPage()

    return {
      handleBindServeRefundChange,
      bindRefunList,
      handleClearClick,
      handleShopChnage,
      shopTeacherList,
      shopList,
      changeDialogVisible,
      refundRules,
      handleSureRefundClick,
      ruleForm,
      detail,
      refundType,
      totalPayPrice,
      ruleFormRef,
      cikaInfo,
      handleRefundInput,
      changePrice,
      COMPANY_ID,
	    cikaRefundAmount,
      shopOldTeacherList
    }
  }
})
</script>

<style scoped lang="less">
.refund-dialog {
  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }

  .shop-teacher {
    display: flex;
    justify-content: space-between;

    &:deep(.el-select) {
      width: 350px;
    }
  }

  .card {
    .title {
      justify-content: space-between;
      align-items: center;
      background-color: rgb(239, 239, 239);
      margin-bottom: 10px;
      padding: 0 10px;

      .left {
        display: flex;
        align-items: center;

        img {
          width: 45px;
          margin-right: 10px;
        }
      }
    }

    .card-form {
      padding: 0 10px;

      & > div {
        flex: 1;
      }
    }

    .title,
    .card-form {
      display: flex;
    }
  }

  .deduct-price {
    & > div {
      display: flex;
      margin-bottom: 10px;

      span {
        display: inline-block;
      }

      .sp1 {
        margin-right: 10px;
      }

      .sp2 {
        margin-left: 10px;
      }

      &:deep(.el-input) {
        max-width: 100px;
      }
    }
  }

  .chuzhi-card {
    .title {
      padding: 0 10px;
      display: flex;
      background-color: rgb(239, 239, 239);

      .left,
      .right {
        display: flex;
        flex: 1;
        align-items: center;

        .info {
          flex: 1;
        }

        img {
          width: 40px;
          margin-right: 10px;
        }
      }

      .right {
        flex-direction: column;

        .title,
        .casg-value {
          width: 100%;
          justify-content: space-around;
        }

        .casg-value {
          display: flex;
        }
      }
    }
  }

  .project {
    .titles {
      background-color: rgb(239, 239, 239);
      margin-bottom: 10px;
    }

    .form {
      margin-bottom: 10px;
    }

    .titles,
    .form {
      display: flex;
      padding: 0 10px;

      &:deep(.el-input) {
        max-width: 100px;
      }

      & > div {
        flex: 1;
      }
    }
  }

  .total-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding-right: 20px;

    .price {
      color: rgba(212, 48, 48, 1);
      font-size: 20px;
    }
  }

  .refund-money {
    .refund-form,
    .titles {
      display: flex;
      padding: 0 10px;

      &:deep(.el-input) {
        max-width: 100px;
      }

      & > div {
        flex: 1;
      }
    }

    .titles {
      background-color: rgb(239, 239, 239);
      margin-bottom: 10px;
    }
  }
}

&:deep(.teacher-select) {
  .el-input__suffix {
    width: 25px;
    background: url("~@/assets/img/main/base/down.png") no-repeat center center;
  }
}
</style>
