<template>
  <div class="order-info">
    <div class="title">订单信息</div>
    <div class="detail">
      <div v-if="orderDetail" class="pay-state">
        <!-- <template v-if="orderDetail.status == 1"> -->
          <div v-if="orderDetail.payStatus == 1">待支付</div>
          <div v-if="orderDetail.payStatus == 2">已支付</div>
          <div v-if="orderDetail.payStatus == 3">已退款</div>
          <div v-if="orderDetail.payStatus == 4">已取消</div>
        <!-- </template>
        <template v-else>
          <div>已取消</div>
        </template> -->
      </div>
      <div class="item">
        <div class="key">订单编号:</div>
        <div v-if="orderDetail" class="value">{{ orderDetail.orderNo }}</div>
      </div>

      <div v-if="orderDetail && orderDetail.type == 1" class="item">
        <div class="key">房间信息:</div>
        <div v-if="orderDetail && orderDetail.roomInfo != null" class="value">
          {{ orderDetail.roomInfo}}
        </div>
      </div>
      <div class="item">
        <div class="key">下单门店:</div>
        <div v-if="orderDetail" class="value">{{ orderDetail.shopName }}</div>
      </div>
      <div class="item">
        <div class="key">付款时间:</div>
        <div v-if="orderDetail" class="value">
          {{orderDetail.payTime}}
        </div>
      </div>
      <div class="item">
        <div class="key">开单人:</div>
        <div v-if="orderDetail" class="value">
          {{orderDetail.cashierName}}
        </div>
      </div>
      <div class="item">
        <div class="key">到店人:</div>
        <div class="value">{{ orderDetail.userName }}</div>
      </div>
      <div class="item" v-if="orderDetail.orderStatus==5||orderDetail.orderStatus==6">
        <div class="key">取消来源:</div>
        <div class="value">{{ orderDetail.isClient ? '小程序' : '后台' }}</div>
      </div>
      <div class="item" v-if="orderDetail.orderStatus==5||orderDetail.orderStatus==6">
        <div class="key">取消操作人:</div>
        <div v-if="orderDetail.cleanName" class="value">{{ orderDetail.cleanName }}</div>
      </div>
      <div class="item">
        <div class="key">备注：</div>
        <div class="value">{{ orderDetail.remark ||''}}</div>
      </div>
    </div>
    
    <div class="item" style="display: flex;align-items: center;">
      <el-button plain class="add-comment" @click="handleAddComment">备注</el-button>
      <!-- <div><p class="block" style=" padding-left: 20px;">备注：{{ orderDetail.remark ||'无备注'}}</p></div> -->
    </div>
    <orderCommentDialog @refreshPage="refreshPage" :remark="orderDetail.remark" :orderId="orderId"  @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible">
    </orderCommentDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import orderCommentDialog from '../../base-ui/order-comment-dialog/order-comment-dialog.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    orderDetail: {
      type: Object,
      defalult() {
        return {}
      }
    },

  },
  components: {
    orderCommentDialog
  },
  emits: ['refreshPage'],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const orderNo = computed(() => route.query.orderNo)
    const orderId = computed(() => route.query.id)
    const handleAddComment = () => {
      dialogVisible.value = true
    }

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }
    const refreshPage = () => {
      emit('refreshPage')
    }

    return {
      handleAddComment,
      dialogVisible,
      changeDialogVisible,
      orderNo,
      orderId,
      refreshPage
    }

  }
})
</script>

<style scoped lang="less">
.order-info {
  .add-comment {
    margin: 36px 0 36px 36px;
  }

  .detail {
    padding-left: 36px;

    .item {
      display: flex;
      font-size: 14px;
      margin: 10px 0;

      .key {
        min-width: 70px;
        text-align: left;
        color: #555555;
        flex-shrink: 0;
      }

      .value {
        text-align: left;
        color: #111111;
      }
    }

    .pay-state {
      margin: 20px 0;
      font-family: PingFangSC-Medium Helvetica, Arial, Verdana, Tahoma,
        sans-serif;
      font-size: 24px;
      color: #323232;
      line-height: 24px;
    }
  }

  .title {
    position: relative;
    line-height: 48px;
    background-color: rgb(248, 248, 248);
    padding-left: 26px;
  }

  .title::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 20px;
    background-color: #2a82e4;
    left: 12px;
    top: 14px;
  }
}
</style>




