<template>
  <div class="user-info">
    <div class="title">客户信息</div>
    <div class="detail">
      <div class="uname">
        <template v-if="orderDetail">
          <img v-if="orderDetail.userAva != 'default_ava.png'" :src="orderDetail.userAva"
            alt="">
          <el-icon v-else>
            <User />
          </el-icon>
          <el-link class="user-name" @click="handleDetailClick">{{ orderDetail.userName }}</el-link>
          <!-- <div class="user-name">{{ orderDetail.userRealname }}</div> -->
        </template>
      </div>
      <div class="item">
        <div class="key">客户姓名:</div>
        <div v-if="orderDetail" class="value">{{ orderDetail.userName }}</div>
      </div>
      <div class="item">
        <div class="key">联系电话:</div>
        <div v-if="orderDetail" class="value">{{ orderDetail.userPhone }}</div>
      </div>

    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onBeforeUnmount, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    orderDetail: {
      type: Object,
      defalult() {
        return {}
      }
    }
  },
  components: {

  },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    onBeforeUnmount(() => {
      store.commit('order/changeRemoveOrderDetail',)
    })
    const handleDetailClick =() => {
      router.push({
        path: '/main/customer/detail',
        query: {
          userId: props.orderDetail.userId,
          phone: props.orderDetail.userMobile
        }
      })
    }

    return {
      handleDetailClick
    }

  }
})
</script>

<style scoped lang="less">
.user-info {
  .detail {
    padding-left: 36px;

    .uname {
      margin: 20px 0;
      display: flex;
      align-items: center;

      .user-name {
        margin-left: 10px;
        line-height: 40px;
        font-size: 24px;
        // color: #111111;
        letter-spacing: 0;

      }

      img {
        width: 38px;


      }

      i {
        font-size: 38px;
      }
    }

    .item {
      display: flex;
      font-size: 14px;
      margin: 10px 0;

      .key {
        min-width: 70px;
        text-align: left;
        color: #555555;
        flex-shrink: 0;
      }

      .value {
        text-align: left;
        color: #111111;
      }
    }
  }

  .title {
    position: relative;
    line-height: 48px;
    background-color: rgb(248, 248, 248);
    padding-left: 26px;
  }

  .title::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 20px;
    background-color: #2a82e4;
    left: 12px;
    top: 14px;
  }
}
</style>




