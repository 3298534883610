import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'

export function popup(tips,success) {
  ElMessageBox.confirm(tips, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      success&&success()
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消'
      })
    })
}
