<template>
  <div class="order-detail">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="order-detail-content">
      <steps :orderDetail="orderDetail"></steps>
      <orderInfo :orderDetail="orderDetail" @refreshPage="refreshPage"></orderInfo>
      <userInfo :orderDetail="orderDetail"></userInfo>
      <consumeInfo
        :serveInfo="serveInfo"
        @refreshPage="refreshPage"
        :orderDetail="orderDetail"
      ></consumeInfo>
      <refundInfo v-if="orderDetail.extraRefundList&&orderDetail.extraRefundList.length" :extraRefundList="orderDetail.extraRefundList"></refundInfo>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { breadcrumbList, getPayTypeStrFunc } from './config'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import orderInfo from './cpns/order-info/order-info.vue'
import consumeInfo from './cpns/consume-info/consume-info.vue'
import userInfo from './cpns/user-info/user-info.vue'
import { COMPANY_ID } from '@/common/constant'
import { useRoute } from 'vue-router'
import steps from './cpns/steps/steps'
import { getOrderDetail, getServeRefundInfo, getTukuanInfo2,getOrderDetailInfo,getCardRefundInfo } from '@/service/main/order'
import refundInfo from './cpns/refund-info/refund-info.vue'
export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    orderInfo,
    consumeInfo,
    userInfo,
    steps,
    refundInfo
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const orderNo = computed(() => route.query.orderNo)
    const orderDetail = ref('')

    const refreshPage = () => {
      initPage()
    }

    const serveInfo = ref([])
    const initPage = async () => {
      const res = await getOrderDetailInfo(route.query.id)
      if (res.data.extraRefundList&&res.data.extraRefundList.length) {
        res.data.extraRefundList.map(item=>{
          item.refundTime=res.data.refundTime||"-",
          item.refundUser=res.data.refundUser||"-"
        })
      }
      orderDetail.value = {...res.data,type:route.query.type}
      if (route.query.type==1) {
        const serveInfoData = await getServeRefundInfo(route.query.id)
        serveInfo.value = serveInfoData.data
      }else{
        const serveInfoData = await getCardRefundInfo(route.query.id)
        serveInfo.value = serveInfoData.data
      }
    }
    initPage()



    return {
      breadcrumbList,
      breadcrumb,
      orderDetail,
      refreshPage,
      serveInfo
    }

  }
})
</script>

<style scoped lang="less">
.order-detail-content {
  min-width: 980px;
  padding: 20px;
  margin: 20px;
  background-color: #fff;
}
</style>
